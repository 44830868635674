<template>
  <DataTable v-if="created" :options="options" />
</template>

<script>
import { PAYMENT_TRACK_TYPES, PAYMENT_STATUSES } from '@tutti/constants';

export default {
  data() {
    return {
      created: false,
      options: {
        action: {
          hideEdit: true,
        },
        export: false,
        sync: true,
        filters: [],
        headers: [
          {
            text: 'Payment Number',
            value: 'paymentNumber',
            filter: true,
            capitalize: true,
            component: { name: 'dtView', props: { name: 'payment', permission: 'payment' } },
          },
          { text: 'reason', value: 'reason' },
          { text: 'Cost', value: 'cost', width: '100px', align: 'center', component: 'dtPrice' },
          { text: 'Total Refunded', value: 'totalRefunded', width: '180px', align: 'center', component: 'dtPrice' },
          {
            text: 'Host profile',
            value: 'accountId',
            sortable: false,
            filter: true,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Payment status',
            value: 'paymentStatusId.name',
            width: '200px',
          },
          {
            text: 'Payment type',
            value: 'paymentType',
            width: '200px',
            capitalize: true,
          },
          {
            text: 'Booking number',
            value: 'bookingId',
            sortable: false,
            filter: true,
            component: { name: 'dtView', props: { name: 'booking', permission: 'booking' } },
          },
        ],
      },
    };
  },
  created() {
    this.options.filters = [
      {
        label: 'Payment status',
        items: PAYMENT_STATUSES,
        key: 'paymentStatus',
      },
      {
        label: 'Payment type',
        items: PAYMENT_TRACK_TYPES,
        key: 'paymentType',
      },
    ];

    this.created = true;
  },
};
</script>
